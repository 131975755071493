<template>
  <MainPopup />

  <div class="content_container main">
    <!--visualBanner 시작-->
    <div class="c_section-gap visualBanner">
      <div class="c_inner">
        <swiper v-bind="swiperOption">
          <swiper-slide v-for="(item, idx) in this.ad_top" :key="idx">
            <a :href="item.url" target="_blank">
              <img :src="item.path + item.save_name" alt="" />
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!--visualBanner 끝-->

    <!--memberInfo 시작-->
    <div v-if="this.$store.state.access_token.access_token" class="c_section-gap memberInfo">
      <!-- <div class="c_section-gap memberInfo" v-if="this.access_token"> -->
      <div class="c_inner">
        <router-link to="/mypage" class="contents">
          <div class="greetingBox">
            <p class="greetingMent">
              <strong>{{ this.$store.state.user.user.name }}</strong
              >님 Today 그린 마인드!
            </p>
            <!-- <div class="iconBox">
							<img src="@/assets/images/common/icon_next.svg" alt="img" />
						</div> -->
          </div>
          <div :class="`creditInfoBox ${levelColor}`">
            <div class="level_wrap">
              <p class="level">{{ levelColor }} Level</p>
              <div class="creditInfo">
                <span class="count">{{ this.$store.state.user.user.meta_token }}</span>
                <span class="slush">/</span>
                <div class="credit">
                  <img src="@/assets/images/common/tag.png" alt="tag" />
                  <span> E-크레딧 </span>
                </div>
              </div>
            </div>
            <div class="ProgessBar">
              <div class="fill"></div>
            </div>
            <p v-if="levelColor === 'red'" class="guideTextBox">조금 더 달려봅시다.</p>
            <p v-else-if="levelColor === 'blue'" class="guideTextBox">이제 우리 곧 그린레벨입니다.</p>
            <p v-else-if="levelColor === 'green'" class="guideTextBox">그린레벨이군요. 당신을 리스펙합니다.</p>
          </div>
        </router-link>
      </div>
    </div>
    <!--memberInfo 끝-->

    <!--mainpageMenu 시작-->
    <div class="c_section-gap mainpageMenu">
      <div class="c_inner">
        <div class="contents">
          <div class="box_container">
            <router-link to="/product" class="box01 c_boxStyle wrap">
              <div class="title_wrap">
                <p class="title">업사이클 센터</p>
              </div>
              <div class="img_wrap">
                <button type="button" class="c_btn" @click.stop.prevent="this.$router.push('/register/provider')">
                  소재&제품 등록
                </button>
                <div class="iconBox_wrap">
                  <div class="iconBox">
                    <img src="@/assets/images/main/icon_mainMenu01.svg" alt="img" />
                  </div>
                </div>
              </div>
              <!-- <div class="img_wrap">
								<router-link to="/register/provider" class="c_btn">소재&제품 등록</router-link>
								<div class="iconBox">
									<img src="@/assets/images/main/icon_mainMenu01.png" alt="img" />
								</div>
							</div> -->
            </router-link>

            <div class="box02">
              <router-link to="/provider" class="c_boxStyle wrap">
                <div class="title_wrap">
                  <p class="title">업셀러 센터</p>
                </div>
                <div class="img_wrap">
                  <button type="button" class="c_btn" @click.stop.prevent="this.$router.push('/register/partner')">
                    공급자 등록
                  </button>
                  <div class="iconBox_wrap">
                    <div class="iconBox">
                      <img src="@/assets/images/main/icon_mainMenu02.svg" alt="img" />
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/seller" class="c_boxStyle wrap">
                <div class="title_wrap">
                  <p class="title">리해버 센터</p>
                </div>
                <div class="img_wrap">
                  <button type="button" class="c_btn" @click.stop.prevent="this.$router.push('/register/partner')">
                    수요자 등록
                  </button>
                  <div class="iconBox_wrap">
                    <div class="iconBox">
                      <img src="@/assets/images/main/icon_mainMenu03.svg" alt="img" />
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="box03 c_boxStyle wrap">
            <div class="title_wrap">
              <p class="title">그린 서비스</p>
            </div>
            <ul class="service_list">
              <li class="item" @click="showPopup01 = true">
                <div class="iconBox">
                  <img src="@/assets/images/main/icon_mainMenu04.svg" alt="img" />
                </div>
                <p class="title">소재기부</p>
              </li>
              <li class="item" @click="showPopup02 = true">
                <div class="iconBox">
                  <img src="@/assets/images/main/icon_mainMenu05.svg" alt="img" />
                </div>
                <p class="title">중고판매</p>
              </li>
              <li class="item" @click="showPopup03 = true">
                <div class="iconBox">
                  <img src="@/assets/images/main/icon_mainMenu06.svg" alt="img" />
                </div>
                <p class="title">후원하기</p>
              </li>
            </ul>
          </div>
          <div class="centerBox c_boxStyle wrap">
            <div class="title_wrap">
              <p class="title">업사이클 센터</p>
              <router-link to="/product" class="textLink">전체보기</router-link>
            </div>
            <swiper v-if="upcycle_list.length > 0" class="center_list" v-bind="centerListSwiperOption">
              <swiper-slide
                class="item"
                v-for="(item, idx) in upcycle_list"
                :key="idx"
                @click="this.$router.push(`/product/detail/${item.product_pk}`)"
              >
                <div class="imgBox">
                  <video
                    v-if="item.save_name.includes('.mp4')"
                    :src="item.path + item.save_name"
                    :alt="item.original_name"
                    autoPlay
                    playsinline
                    loop
                    muted
                  ></video>
                  <img v-else :src="item.path + item.save_name" :alt="item.original_name" v-noImg />
                </div>
                <p class="title">{{ item.name }}</p>
                <p class="category">{{ item.category }}</p>
                <div class="symbol">
                  <img v-if="item.eco_yn === '친환경 소재'" src="@/assets/images/common/symbol_eco.png" alt="eco" />
                  <img
                    v-else-if="item.eco_yn === '업사이클링 제품'"
                    src="@/assets/images/common/symbol_upcycling.png"
                    alt="upcycling"
                  />
                  <img
                    v-else-if="item.eco_yn === '재활용 제품'"
                    src="@/assets/images/common/symbol_recycling.png"
                    alt="recycling"
                  />
                </div>
              </swiper-slide>
            </swiper>
            <p v-else class="noData">등록된 업사이클이 없습니다.</p>
          </div>
          <div class="centerBox c_boxStyle wrap">
            <div class="title_wrap">
              <p class="title">업셀러 센터</p>
              <router-link to="/provider" class="textLink">전체보기</router-link>
            </div>
            <swiper v-if="upseller_list.length > 0" class="center_list" v-bind="centerListSwiperOption">
              <swiper-slide
                class="item"
                v-for="(item, idx) in upseller_list"
                :key="idx"
                @click="this.$router.push(`/provider/detail/${item.id}`)"
              >
                <div class="imgBox">
                  <img v-if="item.path + item.save_name" :src="item.path + item.save_name" :alt="item.original_name" />
                  <img v-else src="@/assets/images/common/charactor_02.png" alt="프로필사진" />
                </div>
                <p class="title">{{ item.name }}</p>
                <p class="category">
                  {{ item.category[0].category
                  }}<span v-if="item.category.length > 1"> 외 {{ item.category.length - 1 }}개</span>
                </p>
              </swiper-slide>
            </swiper>
            <p v-else class="noData">등록된 업셀러가 없습니다.</p>
          </div>
          <div class="centerBox c_boxStyle wrap">
            <div class="title_wrap">
              <p class="title">리해버 센터</p>
              <router-link to="/seller" class="textLink">전체보기</router-link>
            </div>

            <swiper v-if="rehaver_list.length > 0" class="center_list" v-bind="centerListSwiperOption">
              <swiper-slide
                class="item"
                v-for="(item, idx) in rehaver_list"
                :key="idx"
                @click="this.$router.push(`/seller/detail/${item.id}`)"
              >
                <div class="imgBox">
                  <img v-if="item.path + item.save_name" :src="item.path + item.save_name" :alt="item.original_name" />
                  <img v-else src="@/assets/images/common/charactor_02.png" alt="프로필사진" />
                </div>
                <p class="title">{{ item.name }}</p>
                <p class="category">
                  {{ item.category[0]?.category
                  }}<span v-if="(item.category?.length ?? 0) > 1"> 외 {{ item.category.length - 1 }}개</span>
                </p>
              </swiper-slide>
            </swiper>
            <p v-else class="noData">등록된 리해버가 없습니다.</p>
          </div>

          <div class="credit c_boxStyle wrap">
            <div class="title_wrap">
              <p class="title">E 크레딧 (Environment Credit)</p>
            </div>
            <div class="iconBox">
              <img src="@/assets/images/main/img_credit.png" alt="img" />
            </div>
            <div class="text">
              <p>
                <span><strong>E</strong> 크레딧</span> 은 플랫폼 내에서 거래할 수 있는 거래도구입니다.
              </p>
              <p>
                <span><strong>E</strong> 크레딧</span> 충전을 통해 소재, 아이디어, 제품을 거래할 수 있습니다.
              </p>
              <p>
                <span><strong>E</strong> 크레딧</span> 은 환경보호를 위해 구매액의 0.1%는 리해브에 등록되어있는
                업사이클링 스타트업 또는 환경단체에 기부됩니다.
              </p>
            </div>
            <button class="c_btn" @click="showTicketPopup = true">E크레딧 정책</button>
            <!-- <router-link class="c_btn" to="/mypage/MypageBuyToken">충전하기</router-link> -->
          </div>

          <div class="campaign c_boxStyle wrap">
            <div class="title_wrap">
              <p class="title">자원순환 캠페인</p>
              <!-- <router-link to="" class="textLink">전체보기</router-link> -->
              <!-- <a href="javascript:void(0)" class="textLink">전체보기</a> -->
            </div>
            <div class="campaign_list">
              <swiper
                class="item_list"
                v-if="ad_mid_seller.length > 0"
                v-bind="ad_mid_seller.length > 1 ? midAdSwiperOption : { ...midAdSwiperOption, autoplay: false }"
              >
                <swiper-slide class="item" v-for="(item, idx) in ad_mid_seller" :key="idx">
                  <a @click="onMidAdClick(item, 'seller')" class="imgBox">
                    <video
                      v-if="item.save_name.includes('.mp4')"
                      :src="item.path + item.save_name"
                      :alt="item.original_name"
                      autoPlay
                      playsinline
                      loop
                      muted
                    ></video>
                    <img v-else :src="item.path + item.save_name" :alt="item.original_name" />
                  </a>
                  <a @click="onMidAdClick(item, 'seller')" class="textLink"
                    ><span>{{ item.name ? `${item.name}` : "바로가기" }}</span></a
                  >
                </swiper-slide>
              </swiper>
              <swiper
                class="item_list"
                v-if="ad_mid_provider.length > 0"
                v-bind="ad_mid_provider.length > 1 ? midAdSwiperOption : { ...midAdSwiperOption, autoplay: false }"
              >
                <swiper-slide class="item" v-for="(item, idx) in ad_mid_provider" :key="idx">
                  <a @click="onMidAdClick(item, 'provider')" class="imgBox">
                    <video
                      v-if="item.save_name.includes('.mp4')"
                      :src="item.path + item.save_name"
                      :alt="item.original_name"
                      autoPlay
                      playsinline
                      loop
                      muted
                    ></video>
                    <img v-else :src="item.path + item.save_name" :alt="item.original_name" />
                  </a>
                  <a @click="onMidAdClick(item, 'provider')" class="textLink"
                    ><span>{{ item.product_name ? `${item.product_name}` : "바로가기" }}</span></a
                  >
                </swiper-slide>
              </swiper>
              <div v-else class="item_list">캠페인이 없습니다</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--visualBanner 시작-->
    <div class="c_section-gap visualBanner">
      <div class="c_inner">
        <swiper v-bind="swiperOption">
          <swiper-slide v-for="(item, idx) in this.ad_bot" :key="idx">
            <a :href="item.url" target="_blank">
              <img :src="item.path + item.save_name" alt="" />
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!--visualBanner 끝-->

    <GreenServiceModal01 :showPopup="showPopup01" @closePopup="showPopup01 = false" />
    <GreenServiceModal02 :showPopup="showPopup02" @closePopup="showPopup02 = false" />
    <GreenServiceModal03 :showPopup="showPopup03" @closePopup="showPopup03 = false" />

    <TicketPopup :showTicketPopup="showTicketPopup" @close-ticketpopup="closeTicketPopup" />
    <!--mainpageMenu 끝-->
  </div>

  <ToTop />
  <Footer />
  <FooterMenu />
</template>

<script>
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Autoplay, Navigation]);

import { mapState } from "vuex";

import { numberFormat } from "@/utils/numberFormat";
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { counting } from "@/utils/counting";

import Footer from "@/components/Footer";
import FooterMenu from "@/components/FooterMenu";
import MainPopup from "@/components/MainPopup";
// import HomeTabs from '@/components/Home/HomeTabs'
import ToTop from "@/components/ToTop";
import TicketPopup from "@/components/TicketPopup";
import GreenServiceModal01 from "@/components/Home/GreenServiceModal01";
import GreenServiceModal02 from "@/components/Home/GreenServiceModal02";
import GreenServiceModal03 from "@/components/Home/GreenServiceModal03";

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    Footer,
    FooterMenu,
    // HomeTabs,
    GreenServiceModal01,
    GreenServiceModal02,
    GreenServiceModal03,
    MainPopup,
    TicketPopup,
    ToTop,
  },
  mixins: [numberFormat, fetchData, postData, counting],
  computed: {
    ...mapState("user", ["user"]),
    ad_mid_provider() {
      return this.ad_mid.filter((el) => el.ad_division == "P");
    },
    ad_mid_seller() {
      return this.ad_mid.filter((el) => el.ad_division == "S");
    },
    levelColor() {
      const meta_token = this.$store.state.user.user.meta_token;
      if (meta_token >= 500) {
        return "green";
      } else if (meta_token >= 300) {
        return "blue";
      } else {
        return "red";
      }
    },
  },
  data() {
    return {
      ad_top: [],
      ad_mid: [], // TODO: 없음
      ad_bot: [],

      upcycle_list: [],
      upseller_list: [],
      rehaver_list: [],

      isFetched: false,
      showPopup01: false,
      showPopup02: false,
      showPopup03: false,
      showTicketPopup: false,

      swiperOption: {
        // speed: 600,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },

      centerListSwiperOption: {
        slidesPerView: "auto",
        navigation: true,
        spaceBetween: 10,
      },

      midAdSwiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
      },
    };
  },
  created() {
    this.socialLogin();
  },
  mounted() {
    this.fetchBasicData();
    this.fetchUserInfo();
  },
  methods: {
    socialLogin() {
      // console.log(this.$route.query);
      if (this.$route.query.t) {
        this.postData("/tSignin", { t: this.$route.query.t }, (data) => {
          this.$store.commit("user/signin", data.user);
        });
      }
    },
    fetchUserInfo() {
      if (!this.$store.state.access_token.access_token) return;
      this.fetchData("/getUserInfo", (data) => {
        this.$store.state.user.user = data.user;
      });
    },
    fetchBasicData() {
      this.fetchData("/getBasic?page=home", (data) => {
        this.category = data?.category;
        this.ad_top = data.ad_top;
        this.ad_mid = data.ad_mid;
        this.ad_bot = data.ad_bot;
        this.upcycle_list = data.upcycle_list;
        this.upseller_list = data.upseller_list;
        this.rehaver_list = data.rehaver_list;
        this.isFetched = true;
      });
    },
    closeTicketPopup() {
      this.showTicketPopup = false;
    },
    onMidAdClick(item, type) {
      if (item.url) {
        window.open(item.url, "_blank");
      } else {
        if (type === "seller") this.$router.push(`/seller/detail/${item.pk}`);
        else this.$router.push(`/product/detail/${item.pk}`);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/main.css"></style>

<template>
	<!--헤더 시작-->
	<div class="header_container whiteBg">
		<header>
			<div class="left">
				<router-link @click="navOpen('remove')" to="/" class="logo">
					<img src="@/assets/images/bi/logo.svg" alt="symbol" class="symbol" />
					<img src="@/assets/images/bi/img_slogan.svg" alt="slogan" class="slogan" />
				</router-link>
				<!-- <nav>
          <ul>
            <li>
              <router-link to="/ai">AI 매칭</router-link>
            </li>
            <li>
              <router-link to="/seller">판매자 보기</router-link>
            </li>
            <li>
              <router-link to="/provider">공급자 보기</router-link>
            </li>
            <li>
              <router-link to="/product">상품 보기</router-link>
            </li>
            <li>
              <router-link to="/request">도움 요청</router-link>
            </li>
          </ul>
        </nav> -->
			</div>

			<div class="right">
				<template v-if="this.windowSize > 767">
					<router-link @click="navOpen('remove')" to="/signin" class="login" v-if="!this.access_token"
						>로그인</router-link
					>
					<router-link @click="navOpen('remove')" to="/mypage" class="login" v-if="this.access_token">
						<span
							v-bind:class="
								this.user.user_division == '판매자'
									? 'label_seller'
									: this.user.user_division == '공급자'
									? 'label_provider'
									: !this.user.user_division
									? 'label_none'
									: ''
							"
							>{{ this.user.user_division === "판매자" ? "리해버" : this.user.user_division === "공급자" ? "업셀러" : "" }}</span
						>
						<span v-if="this.user.name">{{ this.user.name }} 님</span><span v-if="!this.user.name">마이페이지</span>
					</router-link>
					<a class="login" v-if="this.access_token" @click="logout" href="javascript:void(0)">로그아웃</a>
				</template>
				<!-- <router-link
          to="/register/partner"
          class="partner"
          v-if="!this.user.user_division && this.access_token"
        >
          <p class="menu_register">파트너 등록</p>
        </router-link>
        <router-link
          to="/register/provider"
          v-else-if="this.user.user_division == '공급자'"
        >
          <p class="menu_register">상품 등록</p>
        </router-link>
        <router-link to="/register/seller" v-else>
          <p class="menu_register">매칭 등록</p>
        </router-link> -->

				<div class="btn_nav" @click="navOpen">
					<!-- <img src="@/assets/images/common/btn_header.svg" class="menu"/> -->
					<!-- <img src="@/assets/images/common/btn_header_hover.svg" class="close"/> -->
					<div class="top"></div>
					<div class="mid"></div>
					<div class="bottom"></div>
				</div>
			</div>
		</header>
	</div>
	<!--헤더 끝-->

	<!--헤더 탭 시작-->
	<div class="header_tab">
		<nav>
			<ul>
				<template v-if="this.windowSize <= 767">
					<li v-if="!this.access_token">
						<router-link @click="navOpen('remove')" to="/signin" class="login">로그인</router-link>
					</li>
					<li v-if="this.access_token">
						<router-link @click="navOpen('remove')" to="/mypage" class="login">
							<span
								v-bind:class="
									this.user.user_division == '판매자'
										? 'label_seller'
										: this.user.user_division == '공급자'
										? 'label_provider'
										: !this.user.user_division
										? 'label_none'
										: ''
								"
								>{{ this.user.user_division === "판매자" ? "리해버" : this.user.user_division === "공급자" ? "업셀러" : "" }}</span
							>
							<span v-if="this.user.name" class="name">{{ this.user.name }} 님</span
							><span v-if="!this.user.name" class="name">마이페이지</span>
						</router-link>
					</li>
					<li v-if="this.access_token">
						<a class="login" @click="logout" href="javascript:void(0)">로그아웃</a>
					</li>
				</template>
				<li>
					<router-link @click="navOpen('remove')" to="/ai">AI 매칭</router-link>
				</li>
				<li>
					<router-link @click="navOpen('remove')" to="/seller">리해버 센터</router-link>
				</li>
				<li>
					<router-link @click="navOpen('remove')" to="/provider">업셀러 센터</router-link>
				</li>
				<li>
					<router-link @click="navOpen('remove')" to="/product">업사이클 센터</router-link>
				</li>
				<li>
					<router-link @click="navOpen('remove')" to="/request">자원순환 가이드</router-link>
				</li>
			</ul>
			<router-link
				@click="navOpen('remove')"
				to="/register/partner"
				class="partner"
				v-if="!this.user.user_division && this.access_token"
			>
				<p class="menu_register">파트너 등록</p>
			</router-link>
			<router-link @click="navOpen('remove')" to="/register/provider" v-else-if="this.user.user_division == '공급자'">
				<p class="menu_register">센터 소재 | 상품 등록</p>
			</router-link>
			<router-link @click="navOpen('remove')" to="/register/seller" v-else>
				<p class="menu_register">센터 매칭 등록</p>
			</router-link>
		</nav>
	</div>
	<!--헤더 탭 끝-->
</template>

<script>
	import { mapState } from "vuex";

	export default {
		computed: {
			...mapState("user", ["user"]),
			...mapState("access_token", ["access_token"]),
		},
		mounted() {
			window.addEventListener("resize", this.getWindowSize);
		},
		unmounted() {
			window.removeEventListener("scroll", this.watchScroll);
		},
		watch: {
			$route() {
				// $route(to) {
				// if (to.path == "/") {
				// 	window.addEventListener("scroll", this.watchScroll);
				// } else {
				// 	window.removeEventListener("scroll", this.watchScroll);
				// 	// document.querySelector(".header_container").classList.remove("transparent");
				// }
				this.navOpen("remove");
			},
		},
		data() {
			return {
				windowSize: window.innerWidth,
			};
		},
		methods: {
			// 로그아웃
			logout() {
				this.$store.commit("user/logout");
			},
			watchScroll() {
				// if (this.$route.path == "/") {
				// 	if (window.scrollY > 20) {
				// 		document.querySelector(".header_container").classList.remove("transparent");
				// 	} else {
				// 		document.querySelector(".header_container").classList.add("transparent");
				// 	}
				// }
			},
			getWindowSize() {
				this.windowSize = window.innerWidth;
			},
			navOpen(action = "toggle") {
				const btn_nav = document.querySelector(".btn_nav");
				const header_tab = document.querySelector(".header_tab");
				if (action == "remove") {
					btn_nav.classList.remove("on");
					header_tab.classList.remove("on");
				} else {
					btn_nav.classList.toggle("on");
					header_tab.classList.toggle("on");
				}
			},
		},
	};
</script>

<style scoped src="@/assets/css/components/header.css"></style>

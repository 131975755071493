<template>
	<div>
		<div class="screen_filter" v-if="this.showTicketPopup"></div>
		<transition name="popup">
			<!--pop-up 시작-->
			<div class="popup" v-if="this.showTicketPopup">
				<div class="btn_wrap">
					<button type="button" @click="closePopup">
						<img src="@/assets/images/common/icon_close_w.svg" alt="닫기 버튼" />
					</button>
				</div>
				<div class="inner">
					<div class="title">
						<!-- <h1>거래토큰</h1> -->
						<p><span>"E-크레딧"</span> 으로<br />업셀러와 리해버의 거래를 활성화합니다.</p>
					</div>
					<div class="ticket_banner">
						<div class="image">
							<img src="@/assets/images/main/illu_token_01.svg" alt="token01" />
						</div>
						<div class="text">
							<div class="title_wrap">
								<h1>E-크레딧의 "구매"</h1>
								<router-link to="/mypage/MypageBuyToken"
									><button type="button" class="common_button">E-크레딧 구매</button>
								</router-link>
							</div>
							<p>
								E-크레딧은 개당 1만원입니다.<br />
								기본 3개부터 구입할 수 있으며, 회원가입 시 등록된 카드에서 매월 자동 갱신되거나 매월 3만원의 자동이체로
								구매할 수 있습니다.
							</p>
						</div>
					</div>

					<div class="ticket_banner">
						<div class="image">
							<img src="@/assets/images/main/illu_token_02.svg" alt="token02" />
						</div>
						<div class="text">
							<div class="title_wrap">
								<h1>E-크레딧의 "사용"</h1>
								<router-link to="/mypage/myToken">
									<button type="button" class="common_button">E-크레딧 내역</button>
								</router-link>
							</div>
							<p>
								E-크레딧은 본 플랫폼에서 판매자와 공급자가 구매하여 사용함으로써 선호도가 높은 제품과 레벨이 높은
								판매자와의 거래를 선점하기 위한 수단으로 활용되거나 샘플을 구입하는 용도로 사용됩니다.
							</p>
						</div>
					</div>

					<div class="ticket_banner">
						<div class="image">
							<img src="@/assets/images/main/illu_token_03.svg" alt="token03" />
						</div>
						<div class="text">
							<div class="title_wrap">
								<h1>E-크레딧의 "가치"</h1>
								<router-link to="/mypage/myToken">
									<button type="button" class="common_button">E-크레딧 내역</button>
								</router-link>
							</div>
							<p>
								회원이 E-크레딧을 많이 보유할수록 레벨이 달라집니다. 판매자의 경우 기본적 구매능력의 확인에, 공급자의
								경우 생산 및 조달능력을 가늠할 수 있는 레벨관리에 적용됩니다. 거래토큰은 판매자 간, 공급자 간,
								판매자-공급자 간 거래도 가능합니다.
							</p>
						</div>
					</div>
				</div>
			</div>
			<!--pop-up 끝-->
		</transition>
	</div>
</template>

<script>
	export default {
		props: {
			showTicketPopup: Boolean,
		},
		methods: {
			closePopup() {
				this.$emit("close-ticketpopup");
			},
		},
	};
</script>

<style scoped src="@/assets/css/components/ticketPopup.css"></style>

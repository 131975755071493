<template>
	<!--푸터 시작-->
	<div class="footer_container">
		<footer>
			<div class="logo">
				<router-link to="/">
					<img src="@/assets/images/bi/logo.svg" alt="logo" />
				</router-link>
			</div>
			<div class="footer_wrap">
				<div class="left">
					<h1>문의 사항은 우측 하단의 채널톡으로 주세요.</h1>
					<ul class="contact">
						<li>
							<div><img src="@/assets/images/common/icon_footer_01.svg" alt="이메일" /></div>
							<!-- <p>rehave100@gmail.com 엘데이터 (대표:이정윤)</p> -->
							<p>lifedataone@gmail.com 엘데이터 (대표:이정윤)</p>
						</li>
						<li>
							<div><img src="@/assets/images/common/icon_footer_02.svg" alt="주소" /></div>
							<!-- <p>
								(본사)경기도 성남시 분당구 운중로 124, <br />
								8층 804호 (운중도, 마크시티블루)
							</p> -->
							<p>
								(본사)충청남도 천안시 서북구 2공단 5로 56 2층<br />
								룩소르퍼스트비즈타워 209, 210호
							</p>
						</li>
						<li class="bottom_text">
							<p>사업자 등록번호 : 199-01-02305</p>
							<p>통신판매신고번호 : 566-충남천안서북-1256</p>
						</li>
					</ul>
				</div>
				<div class="right">
					<ul>
						<li>엘데이터</li>
						<li><router-link to="/introduce">Re:have 소개</router-link></li>
						<li><router-link to="/trendiencer">우리의 비즈니스</router-link></li>
						<li><router-link to="/company">회사정보</router-link></li>
						<li><router-link to="/history">Contact Us</router-link></li>
					</ul>

					<ul>
						<li>Service</li>
						<li><router-link to="/product">상품 보기</router-link></li>
						<li><router-link to="/seller">리해버 보기</router-link></li>
						<li><router-link to="/provider">업셀러 보기</router-link></li>
						<li><router-link to="/WhatsApp">WhatsApp</router-link></li>
						<!-- <li><router-link to="/workplace">Workplace</router-link></li> -->
					</ul>

					<ul>
						<li>Re:have Policies</li>
						<li><router-link to="/policy/term">이용약관</router-link></li>
						<li><router-link to="/policy/privacy">개인정보처리방침</router-link></li>
						<li><router-link to="/policy/data">Data Policy</router-link></li>
						<li><router-link to="/policy/cookie">Cookie Police</router-link></li>
					</ul>

					<ul>
						<li>Help Center</li>
						<li><router-link to="/request">자원순환 가이드센터</router-link></li>
						<!-- <li><router-link to="/GreenHelpCenter">Green Buysell Help Center</router-link></li> -->
						<li><router-link to="/mypage/MypageBuyToken">E크레딧 충전</router-link></li>
						<!-- <li>
							<a
								href="https://docs.google.com/forms/d/1t1TcdK5W3ND4sEJynI0ZbFk1M5hYRhvSzZgHm97fbPc/viewform?edit_requested=true"
								>Trendiencer 서베이</a
							>
						</li> -->
						<!-- <li><router-link to="/TrendiencerRecruit">Trendiencer 모집</router-link></li> -->
						<li><router-link to="/request">Help Center</router-link></li>
					</ul>
				</div>
			</div>
			<div class="footer_bottom">
				<div class="text-left">
					<p>@copyright by L-data 2024 All Rights Reserved.</p>
				</div>
				<div class="icon_link_wrap">
					<div class="text-right">
						<p>Follow Us</p>
					</div>
					<div class="icon_link">
						<img src="@/assets/images/common/icon_sns_01.svg" alt="facebook" />
					</div>
					<div class="icon_link">
						<img src="@/assets/images/common/icon_sns_02.svg" alt="instagram" />
					</div>
					<div class="icon_link">
						<img src="@/assets/images/common/icon_sns_03.svg" alt="twitter" />
					</div>
					<div class="icon_link">
						<img src="@/assets/images/common/icon_sns_04.svg" alt="youtube" />
					</div>
					<div class="icon_link">
						<img src="@/assets/images/common/icon_sns_05.svg" alt="linkin" />
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	export default {
		methods: {
			noPage() {
				this.$store.commit("alert", "준비중인 페이지입니다.");
			},
		},
	};
</script>

<style scoped src="@/assets/css/components/footer.css"></style>

<template>
	<div class="bottomMenu">
		<ul class="bottom_list">
			<li class="item">
				<router-link to="/" :class="{ active: this.currentMenu === 'home' }">
					<img src="@/assets/images/common/icon_home.svg" alt="홈" />
					<img src="@/assets/images/common/icon_home_color.svg" alt="홈" />
					<p class="text">Home</p>
				</router-link>
			</li>
			<li class="item">
				<router-link to="/mypage/myToken" :class="{ active: this.currentMenu === 'credit' }">
					<img src="@/assets/images/common/icon_coupon.svg" alt="E 크레딧" />
					<img src="@/assets/images/common/icon_coupon_color.svg" alt="E 크레딧" />
					<p class="text">E 크레딧</p>
				</router-link>
			</li>
			<li class="item">
				<router-link to="/product" :class="{ active: this.currentMenu === 'product' }">
					<img src="@/assets/images/common/icon_cart.svg" alt="쇼핑몰" />
					<img src="@/assets/images/common/icon_cart_color.svg" alt="쇼핑몰" />
					<p class="text">쇼핑몰</p>
				</router-link>
			</li>
			<li class="item">
				<!-- <router-link to="/campaign">
					<img src="@/assets/images/common/icon_notice.svg" alt="캠페인" />
					<img src="@/assets/images/common/icon_notice_color.svg" alt="캠페인" />
					<p class="text">캠페인</p>
				</router-link> -->
				<a href="javascript:void(0)">
					<img src="@/assets/images/common/icon_notice.svg" alt="캠페인" />
					<img src="@/assets/images/common/icon_notice_color.svg" alt="캠페인" />
					<p class="text">캠페인</p>
				</a>
			</li>
			<li class="item">
				<router-link to="/mypage" :class="{ active: this.currentMenu === 'mypage' }">
					<img src="@/assets/images/common/icon_mypage.svg" alt="My" />
					<img src="@/assets/images/common/icon_mypage_color.svg" alt="My" />
					<p class="text">My</p>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: "FooterMenu",
		data() {
			return {
				isActive: false,
			};
		},
		computed: {
			currentMenu() {
				const pathArr = this.$route.path?.split("/");
				const depth1 = pathArr?.[1];
				const depth2 = pathArr?.[2];
				if (!depth1) {
					return "home";
				} else if (depth2 === "MypageBuyToken" || depth2 === "myToken") {
					return "credit";
				} else {
					return depth1 ?? "";
				}
			},
		},
	};
</script>

<style scoped>
	.bottomMenu {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		display: none;
		background-color: #fff;
		border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
		z-index: 100;
	}
	@media screen and (max-width: 767px) {
		.bottomMenu {
			display: flex;
		}
	}
	.bottomMenu .bottom_list {
		position: relative;
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		height: var(--footer-height);
		padding: 0.4rem 0;
		/* background-color: var(--color-base-mid);
	border-top: 1px solid var(--color-border-mid); */
		box-sizing: border-box;
	}
	.bottomMenu .bottom_list .item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-basis: calc(100% / 6);
	}
	.bottomMenu .bottom_list .item a {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.8rem;
		width: 100%;
		height: 100%;
		aspect-ratio: 1;
	}
	.bottomMenu .bottom_list .item a img {
		width: 20%;
		min-width: 2.8rem;
		max-width: 2.8rem;
	}
	.bottomMenu .bottom_list .item a img:last-of-type {
		display: none;
	}
	.bottomMenu .bottom_list .item a.active img:first-of-type {
		display: none;
	}
	.bottomMenu .bottom_list .item a.active img:last-of-type {
		display: block;
	}
	.bottomMenu .bottom_list .item a .text {
		text-align: center;
		font-size: var(--font-tiny);
		font-weight: var(--font-w-mid);
		color: #222;
		line-height: 1.2;
	}
	.bottomMenu .bottom_list .item a.active .text {
		color: #2091ea;
	}
	.bottomMenu .bottom_list .item.mypage {
		position: relative;
	}
	.bottomMenu .bottom_list .item .mypage a {
		z-index: 1;
	}
</style>

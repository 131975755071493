<template>
	<div class="GreenServiceModal">
		<div class="screen_filter" v-if="this.showPopup"></div>
		<transition name="popup">
			<!--pop-up 시작-->
			<div class="popup" v-if="this.showPopup">
				<div class="btn_wrap">
					<button type="button" @click="closePopup">
						<img src="@/assets/images/common/icon_close_w.svg" alt="닫기 버튼" />
					</button>
				</div>
				<div class="inner">
					<div class="title">
						<div class="iconBox">
							<img src="@/assets/images/main/icon_mainMenu04.svg" alt="img" />
						</div>
						<p>소재 기부하기</p>
					</div>
					<div class="content">
						<div class="textBox">
							<p>소재를 가지고 있는 업셀러 님들은 업사이클 제품을 만드는 업셀러 님들에게 기부를 하실 수 있습니다.</p>
							<p>쓸만한 활용소재를 찾고 있는 제품업셀러 님들께 소재를 기부해 보세요</p>
						</div>
						<div class="btnBox">
							<button type="button" @click="$router.push('/provider')" class="c_btn">기부하기</button>
						</div>
					</div>
				</div>
			</div>
			<!--pop-up 끝-->
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		showPopup: Boolean,
	},
	methods: {
		closePopup() {
			this.$emit("closePopup");
		},
	},
};
</script>

<style scoped src="@/assets/css/components/greenServiceModal.css"></style>

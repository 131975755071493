import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// editor
import CKEditor from "@ckeditor/ckeditor5-vue";
import noImg from "@/utils/noImg";

// axios 기본 url
axios.defaults.baseURL = "https://admin.rehave100.com/api";

let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
	refreshSubscribers.map((callback) => callback(accessToken));
};
const addRefreshSubscriber = (callback) => {
	refreshSubscribers.push(callback);
};

axios.interceptors.response.use(async function (response) {
	// console.log(response.data.code, response.config.url, response.config.headers.authorization, response)

	if (response.data.code == "99105") {
		store.commit("alert", response.data.message);
		store.commit("user/logout");
	} else if (response.data.code == "99104") {
		const originalRequest = response.config;

		if (!isTokenRefreshing) {
			// isTokenRefreshing이 false인 경우에만 token refresh 요청
			isTokenRefreshing = true;
			const refresh_token = store.state.access_token.refresh_token;
			// token refresh 요청
			// console.log('갱신요청')
			const { data } = await axios.get("/requestToken", { params: { refresh_token } });
			// 새로운 토큰 저장
			store.state.access_token.access_token = data.access_token;
			// console.log('새토큰', store.state.access_token.access_token)

			// 새로운 토큰으로 지연되었던 요청 진행
			onTokenRefreshed(store.state.access_token.access_token);
			isTokenRefreshing = false;
		}

		// token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
		const retryOriginalRequest = new Promise((resolve) => {
			// token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
			addRefreshSubscriber((access_token) => {
				// console.log('요청 실행', access_token, originalRequest)
				originalRequest.headers.authorization = access_token;

				// 99104로 요청 실패했던 요청 새로운 accessToken으로 재요청
				resolve(axios(originalRequest));
			});
		});
		return retryOriginalRequest;
	}
	return response;
});

// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App);
app.config.globalProperties.axios = axios;
app.directive("noImg", noImg);
app.use(store).use(router).use(CKEditor).mount("#app");

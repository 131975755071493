<template>
	<Alert />
	<Confirm />
	<SignupPopup />
	<ResignPopup />
	<Loading />

	<Header />

	<router-view />
</template>

<script>
	import Header from "@/components/Header";
	import Alert from "@/components/Alert";
	import Loading from "@/components/Loading.vue";
	import Confirm from "./components/Confirm.vue";
	import SignupPopup from "@/components/SignupPopup";
	import ResignPopup from "@/components/ResignPopup";

	export default {
		components: {
			Header,
			Alert,
			Confirm,
			SignupPopup,
			ResignPopup,
			Loading,
		},
	};
</script>

<style>
	@import "./assets/css/reset.css";
	@import "./assets/css/common.css";
	@import "./assets/css/ckEditor.css";
</style>

<template>
	<div class="GreenServiceModal">
		<div class="screen_filter" v-if="this.showPopup"></div>
		<transition name="popup">
			<!--pop-up 시작-->
			<div class="popup" v-if="this.showPopup">
				<div class="btn_wrap">
					<button type="button" @click="closePopup">
						<img src="@/assets/images/common/icon_close_w.svg" alt="닫기 버튼" />
					</button>
				</div>
				<div class="inner">
					<div class="title">
						<div class="iconBox">
							<img src="@/assets/images/main/icon_mainMenu05.svg" alt="img" />
						</div>
						<p>중고판매</p>
					</div>
					<div class="content">
						<div class="textBox">
							<p>
								업사이클링(새활용)제품을 다 사용하신 경우, 다시 중고로 판매하실 수 있습니다. 우리의 노력으로 업사이클링
								제품을 또 다시 리사이클(재활용) 하는 무한 자원순환을 실현할 수 있습니다.
							</p>
						</div>
						<div class="btnBox">
							<button type="button" @click="onSellClick" class="c_btn">중고판매</button>
						</div>
					</div>
				</div>
			</div>
			<!--pop-up 끝-->
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		showPopup: Boolean,
	},
	methods: {
		closePopup() {
			this.$emit("closePopup");
		},
		onSellClick() {
			this.$router.push("/register/provider");
		},
	},
};
</script>

<style scoped src="@/assets/css/components/greenServiceModal.css"></style>

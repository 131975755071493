import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import axios from "axios";

// 유저 정보 갱신
function getUser(callback = () => {}) {
	if (!store.state.access_token.access_token) return;

	axios
		.get("/getUserInfo", {
			headers: {
				authorization: store.state.access_token.access_token,
			},
		})
		.then((res) => {
			if (res.data.success) {
				store.state.user.user = res.data.user;
			} else {
				store.commit("alert", "유저 정보를 받아오는데 문제가 생겼습니다.");
			}
		})
		.then(() => {
			callback();
		})
		.catch((error) => {
			console.error(error);
		});
}

// 로그인 여부 확인
function checkLogin(next, alertContent = "로그인이 필요합니다") {
	if (store.state.access_token.access_token) {
		next();
	} else {
		store.commit("alert", alertContent);
		next("/signin");
	}
}

// 로그인 & 파트너 가입 여부 확인
function checkAi(next, alertContent = "로그인이 필요합니다") {
	if (store.state.access_token.access_token) {
		getUser(() => {
			if (store.state.user.user.user_division) {
				next();
			} else {
				store.commit("alert", "파트너 가입이 필요합니다");
				next("/register/partner");
			}
		});
	} else {
		store.commit("alert", alertContent);
		next("/signin");
	}
}

// 이미 로그인했을 시 다시 들어가는거 방지
function loginGuard(next, alertContent = "이미 로그인/회원가입 하셨습니다") {
	if (!store.state.access_token.access_token) {
		next();
	} else {
		store.commit("alert", alertContent);
		next("/");
	}
}

// 파트너 가입(user_division) 여부 확인
function checkPartner(next, division) {
	if (store.state.access_token.access_token) {
		getUser(() => {
			if (store.state.user.user.user_division) {
				if (store.state.user.user.user_division != division) {
					store.commit("alert", "잘못된 접근입니다.");
					next(false);
				} else {
					next();
				}
			} else {
				store.commit("alert", "파트너 가입이 필요합니다");
				next("/register/partner");
			}
		});
	} else {
		store.commit("alert", "로그인이 필요합니다");
		next("/signin");
	}
}

const routes = [
	{
		// 홈 화면
		path: "/",
		name: "Home",
		component: Home,
	},

	{
		// AI 매칭
		path: "/ai",
		name: "AI",
		component: () => import(/* webpackChunkName: "AI" */ "../views/AI/AI.vue"),
		beforeEnter: (to, from, next) => {
			checkAi(next);
		},
	},

	{
		// 판매자 - 판매자 보기
		path: "/mainpopup",
		name: "MainPopup",
		component: () => import(/* webpackChunkName: "MainPopup" */ "../components/MainPopup.vue"),
	},

	{
		// 판매자 - 판매자 보기
		path: "/seller",
		name: "Seller",
		component: () => import(/* webpackChunkName: "Seller" */ "../views/Seller/Seller.vue"),
	},
	{
		// 판매자 - 판매자 상세보기
		path: "/seller/detail/:id",
		name: "SellerDetail",
		component: () => import(/* webpackChunkName: "SellerDetail" */ "../views/Seller/SellerDetail.vue"),
		props: true,
	},

	{
		// 매칭 - 매칭 상세 보기
		path: "/matching/detail/:id",
		name: "MatchingDetail",
		component: () => import(/* webpackChunkName: "MatchingDetail" */ "../views/Matching/MatchingDetail.vue"),
		props: true,
	},

	{
		// 공급자 - 공급자 보기
		path: "/provider",
		name: "Provider",
		component: () => import(/* webpackChunkName: "Provider" */ "../views/Provider/Provider.vue"),
	},
	{
		// 공급자 - 공급자 상세보기
		path: "/provider/detail/:id",
		name: "ProviderDetail",
		component: () => import(/* webpackChunkName: "ProviderDetail" */ "../views/Provider/ProviderDetail.vue"),
		props: true,
	},

	{
		// 공급자 - 거래제안하기
		path: "/matchingsuggest",
		name: "MatchingSuggest",
		component: () => import(/* webpackChunkName: "MatchingSuggest" */ "../views/Provider/MatchingSuggest.vue"),
		props: true,
		beforeEnter: (to, from, next) => {
			checkPartner(next, "공급자");
		},
	},

	{
		// 상품 보기
		path: "/product",
		name: "Product",
		component: () => import(/* webpackChunkName: "Product" */ "../views/Product/Product.vue"),
	},
	{
		// 상품 - 상세 보기
		path: "/product/detail/:id",
		name: "ProductDetail",
		component: () => import(/* webpackChunkName: "ProductDetail" */ "../views/Product/ProductDetail.vue"),
		props: true,
	},

	{
		// 이용 문의
		path: "/request",
		name: "Request",
		component: () => import(/* webpackChunkName: "Request" */ "../views/Request/Request.vue"),
	},
	{
		// 이용문의 - 이용문의 상세
		path: "/request/detail/:pk",
		name: "RequestDetail",
		component: () => import(/* webpackChunkName: "RequestDetail" */ "../views/Request/RequestDetail.vue"),
		props: true,
	},
	{
		// 이용문의 - 상품 소싱요청
		path: "/request/sourcing",
		name: "Sourcing",
		component: () => import(/* webpackChunkName: "Sourcing" */ "../views/Request/Sourcing.vue"),
		beforeEnter: (to, from, next) => {
			checkLogin(next);
		},
	},
	{
		// 이용문의 - 거래중개 컨설팅 요청
		path: "/request/consulting",
		name: "Consulting",
		component: () => import(/* webpackChunkName: "Consulting" */ "../views/Request/Consulting.vue"),
		beforeEnter: (to, from, next) => {
			checkLogin(next);
		},
	},
	{
		// 이용문의 - 일반 문의 요청
		path: "/request/inquire",
		name: "Inquire",
		component: () => import(/* webpackChunkName: "Inquire" */ "../views/Request/Inquire.vue"),
		beforeEnter: (to, from, next) => {
			checkLogin(next);
		},
	},

	{
		// 로그인 - 로그인
		path: "/signin",
		name: "Signin",
		component: () => import(/* webpackChunkName: "Signin" */ "../views/Login/Signin.vue"),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
	},
	{
		// 로그인 - 회원가입
		path: "/signup",
		name: "Signup",
		component: () => import(/* webpackChunkName: "Signup" */ "../views/Login/Signup.vue"),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
	},
	{
		// 로그인 - 이메일 회원가입
		path: "/signupemail",
		name: "SignupEmail",
		component: () => import(/* webpackChunkName: "SignupEmail" */ "../views/Login/SignupEmail.vue"),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
	},
	{
		// 비밀번호 찾기
		path: "/findpassword",
		name: "FindPassword",
		component: () => import(/* webpackChunkName: "FindPassword" */ "../views/Login/FindPassword.vue"),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
	},
	{
		// 비밀번호 변경
		path: "/changePassword",
		name: "ChangePassword",
		component: () => import(/* webpackChunkName: "ChangePassword" */ "../views/Login/ChangePassword.vue"),
		beforeEnter: (to, from, next) => {
			if (!to.query.token) {
				store.commit("alert", "잘못된 경로입니다.");
				next("/findpassword");
			} else {
				loginGuard(next);
			}
		},
	},

	{
		// 마이페이지
		path: "/mypage",
		name: "Mypage",
		component: () => import(/* webpackChunkName: "Mypage" */ "../views/Mypage/Mypage.vue"),
		beforeEnter: (to, from, next) => {
			checkLogin(next);
		},
	},
	{
		// 마이페이지 - 각 페이지들
		path: "/mypage/:page",
		component: () => import(/* webpackChunkName: "Mypage" */ "../views/Mypage/Mypage.vue"),
		props: true,
		beforeEnter: (to, from, next) => {
			checkLogin(next);
		},
	},

	{
		// 등록 - 매칭등록(판매자)
		path: "/register/seller",
		name: "RegisterSeller",
		component: () => import(/* webpackChunkName: "RegisterSeller" */ "../views/Register/RegisterSeller.vue"),
		beforeEnter: (to, from, next) => {
			checkPartner(next, "판매자");
		},
	},
	{
		// 등록 - 매칭등록(판매자)
		path: "/update/seller/:matching",
		name: "UpdateSeller",
		component: () => import(/* webpackChunkName: "RegisterSeller" */ "../views/Register/RegisterSeller.vue"),
		beforeEnter: (to, from, next) => {
			checkPartner(next, "판매자");
		},
	},
	{
		// 등록 - 상품등록(공급자)
		path: "/register/provider",
		name: "RegisterProvider",
		component: () => import(/* webpackChunkName: "RegisterProvider" */ "../views/Register/RegisterProvider.vue"),
		beforeEnter: (to, from, next) => {
			checkPartner(next, "공급자");
		},
	},
	{
		// 등록 - 상품등록(공급자)
		path: "/update/provider/:product",
		name: "UpdateProvider",
		component: () => import(/* webpackChunkName: "RegisterProvider" */ "../views/Register/RegisterProvider.vue"),
		beforeEnter: (to, from, next) => {
			checkPartner(next, "공급자");
		},
	},
	{
		// 등록 - 파트너등록
		path: "/register/partner",
		name: "RegisterPartner",
		component: () => import(/* webpackChunkName: "PartnerSignin" */ "../views/Register/RegisterPartner.vue"),
		beforeEnter: (to, from, next) => {
			if (store.state.access_token.access_token) {
				if (store.state.user.user.user_division) {
					store.commit("alert", "이미 파트너에 가입하셨습니다.");
					next("/");
				} else {
					next();
				}
			} else {
				store.commit("alert", "로그인이 필요합니다");
				next("/signin");
			}
		},
	},

	{
		// 시장분석
		path: "/marketAnalyze/:which?",
		name: "MarketAnalyze",
		component: () => import(/* webpackChunkName: "MarketAnalyze" */ "../views/MarketAnalyze/MarketAnalyze.vue"),
		props: true,
	},

	{
		// 리해브 소개
		path: "/introduce",
		name: "Introduce",
		component: () => import(/* webpackChunkName: "Introduce" */ "../views/MetaIn/Introduce.vue"),
		props: true,
	},

	{
		// 트렌디언서
		path: "/trendiencer",
		name: "Trendiencer",
		component: () => import(/* webpackChunkName: "Trendiencer" */ "../views/MetaIn/Trendiencer.vue"),
		props: true,
	},
	{
		// 회사소개
		path: "/company",
		name: "Company",
		component: () => import(/* webpackChunkName: "Company" */ "../views/MetaIn/Company.vue"),
		props: true,
	},
	{
		// 연혁
		path: "/history",
		name: "History",
		component: () => import(/* webpackChunkName: "History" */ "../views/MetaIn/History.vue"),
		props: true,
	},

	{
		// WhatsApp
		path: "/WhatsApp",
		name: "WhatsApp",
		component: () => import(/* webpackChunkName: "WhatsApp" */ "../views/Service/WhatsApp.vue"),
		props: true,
	},
	{
		// Workplace
		path: "/workplace",
		name: "Workplace",
		component: () => import(/* webpackChunkName: "Workplace" */ "../views/Service/Workplace.vue"),
		props: true,
	},

	{
		// 정책
		path: "/policy",
		name: "Policy",
		component: () => import(/* webpackChunkName: "Policy" */ "../views/Policy/Policy.vue"),
		children: [
			{
				path: "*",
				redirect: "/policy/term",
			},
			{
				// 이용약관
				path: "term",
				component: () => import(/* webpackChunkName: "Policy" */ "../views/Policy/Term.vue"),
			},
			{
				// 개인정보처리방침
				path: "privacy",
				component: () => import(/* webpackChunkName: "Policy" */ "../views/Policy/Privacy.vue"),
			},
			{
				// Data Policy
				path: "data",
				component: () => import(/* webpackChunkName: "Policy" */ "../views/Policy/Data.vue"),
			},
			{
				// Cookie Policy
				path: "cookie",
				component: () => import(/* webpackChunkName: "Policy" */ "../views/Policy/Cookie.vue"),
			},
		],
	},

	{
		// Green Help Center
		path: "/GreenHelpCenter",
		name: "GreenHelpCenter",
		component: () => import(/* webpackChunkName: "GreenHelpCenter" */ "../views/Help/GreenHelpCenter.vue"),
		props: true,
	},
	{
		// Trendiencer 모집
		path: "/TrendiencerRecruit",
		name: "TrendiRecruit",
		component: () => import(/* webpackChunkName: "TrendiRecruit" */ "../views/Help/TrendiRecruit.vue"),
		props: true,
	},

	{
		// 404 페이지
		path: "/404",
		name: "404",
		component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
	},
	{
		// 존재하지 않는 페이지 404로 리다이렉트
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	},
});

router.beforeEach((to, from) => {
	store.commit("finishLoading"); // 페이지 이동하면 로딩 스피너 초기화
	//checkPartner와 checkAI가 실행되는 경우는 해당 함수에서 getUser()를 하기 때문에 이외의 경우에만 getUser()를 호출 하도록 함.
	if (
		to !== "/register/seller" &&
		to !== "/register/provider" &&
		to !== "/matchingsuggest" &&
		to !== "/ai" &&
		from !== "/signupemail" &&
		from !== "/signup" &&
		from !== "/signin"
	) {
		getUser();
	}
	console.log("beforeEach");
});

export default router;
